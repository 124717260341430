<template>
  <Header></Header>
  <div class="relative p-normal">
    <Logo></Logo>
    <div>
      <h1 class="text-center font-light">Reiniciar contraseña</h1>
    </div>
    <!--    form-->
    <!--    form @submit.prevent="handleLogin" @keydown="clear($event.target.id)"-->

    <form @submit.prevent="resetPassword" class="w-full login-form">
      <div class="p-normal">
        <div class="block-input">
          <!--v-icon name="at" scale="1.4" class="w-16 text-gray-800 dark:text-gray-400" /-->
          <input type="text" id="token" name="token" v-model="user.token" placeholder="Codigo de verificación"
                 class="w-full border-gray-300 focus:border-none" required disabled>
        </div>
<!--        <div class="block-input">-->
<!--          &lt;!&ndash;v-icon name="at" scale="1.4" class="w-16 text-gray-800 dark:text-gray-400" /&ndash;&gt;-->
<!--          <input type="password" id="password" name="email" v-model="user.password" placeholder="Nueva contraseña"-->
<!--                 class="w-full border-gray-300 focus:border-none" required>-->
<!--        </div>-->

        <div class="block-input">
          <div class="flex items-center border border-gray-300 custom-rounded-border">
            <input
              ref="password_input"
              type="password"
              id="password"
              name="password"
              v-model="user.password"
              placeholder="Contraseña"
              class="flex-grow focus:outline-none focus:ring-2 focus:ring-primary input-cancel-border"
              required>
            <i @click="revealPassword('password')" class="flex-none icon-eye px-2 text-xl font-bold"></i>
          </div>
        </div>

<!--        <div class="block-input">-->
<!--          &lt;!&ndash;v-icon name="at" scale="1.4" class="w-16 text-gray-800 dark:text-gray-400" /&ndash;&gt;-->
<!--          <input type="password" id="passwordConfirm" name="email" v-model="password_confirm" placeholder="Confirmar contraseña"-->
<!--                 class="w-full border-gray-300 focus:border-none" required>-->
<!--        </div>-->
        <div class="block-input">
          <div class="flex items-center border border-gray-300 custom-rounded-border">
            <input
              ref="password_confirm"
              type="password"
              id="passwordConfirm"
              name="password"
              v-model="password_confirm"
              placeholder="Confirmar contraseña"
              class="flex-grow focus:outline-none focus:ring-2 focus:ring-primary input-cancel-border"
              required>
            <i @click="revealPassword('passwordConfirm')" class="flex-none icon-eye px-2 text-xl font-bold"></i>
          </div>
        </div>

        <div v-if="password_confirm !== user.password" class="text-red text-left">
          Las contraseñas deben ser iguales
        </div>
        <div v-if="user.password.length < 6" class="text-red text-left">
          La contraseña debe ser mayor a 6 caracteres
        </div>
        <div v-if="errorValue" class="text-red text-left">
          {{ errorValue }}
        </div>
      </div>

      <button :disabled="loading || password_confirm !== user.password || !user.password || user.password.length < 6" class="w-full button uppercase bg-primary text-white flex items-center justify-center space-x-2 disabled:bg-gray-200">
        <a-loader class="w-10 h-6" color="text-white" v-if="loading"/>
        <span>Reiniciar contraseña</span>
      </button>

      <!--div class="flex justify-center">
        <router-link :to="{ name: 'send_password_reset' }" class="my-2 text-sm text-gray-700 dark:text-gray-300">
          {{ lang.login.forgot_password }}
        </router-link>
      </div-->
    </form>
    <div>
      <router-link to="/login" class="text-primary">Ya tengo cuenta</router-link>
    </div>
    <a-alert hide-cancel :show="passwordChanged" title="Cambio de contraseña" @confirm="$router.push({ name: 'Login' })">
      <p class="text-center">¡Su contraseña se ha cambiado satisfactoriamente!</p>
    </a-alert>
    <a-alert hide-cancel :show="passwordChangedFail" title="Cambio de contraseña" @confirm="passwordChangedFail = false">
      <p class="text-center">{{ passwordChangedMessage }}</p>
    </a-alert>
  </div>
</template>

<script>
import Header from '@/components/guest/Header'
import Logo from '@/components/Logo'

export default {
  components: {
    Header,
    Logo
  },
  data () {
    return {
      user: {
        email: '',
        password: '',
        token: ''
      },
      password_confirm: '',
      loading: false,
      sendedEmail: false,
      errorValue: null,
      passwordChanged: false,
      passwordChangedFail: false,
      passwordChangedMessage: ''
    }
  },
  name: 'PasswordReset',
  methods: {
    resetPassword () {
      this.loading = !this.loading
      this.$repository.users
        .recoverPassword(this.user)
        .then(() => {
          this.passwordChanged = !this.passwordChanged
        })
        .catch(({ response }) => {
          if (response?.data?.error_code) {
            this.passwordChangedFail = true
            switch (response?.data?.error_code) {
              case 'EA004': {
                this.passwordChangedMessage = 'Su solicitud ha expirado, por favor genere una nueva.'
                break
              }
              case 'EA005': {
                this.passwordChangedMessage = 'Su solicitud ha expirado, por favor genere una nueva.'
                break
              }
              default: {
                this.passwordChangedMessage = 'No se pudo cambiar la contraseña, por favor intentelo nuevamente.'
                break
              }
            }
          }
        })
        .finally(() => {
          this.loading = !this.loading
        })
    },
    revealPassword (input) {
      const password = this.$refs.password_input
      const passwordConfirm = this.$refs.password_confirm
      if (input === 'password') {
        if (password.type === 'password') {
          password.type = 'text'
        } else {
          password.type = 'password'
        }
      }
      if (input === 'passwordConfirm') {
        if (passwordConfirm.type === 'password') {
          passwordConfirm.type = 'text'
        } else {
          passwordConfirm.type = 'password'
        }
      }
    }
  },
  mounted () {
    if (this.$route.query.token && this.$route.query.email) {
      this.user.token = this.$route.query.token
      this.user.email = this.$route.query.email
    } else {
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>

<style lang="scss" scoped>
.button{
  margin: 10% auto 15px auto;
}
input[type="checkbox"]{
  vertical-align: middle;
}
.remember-input{
}
.remember-label{
  margin-left: 5px;
  vertical-align: middle;
}
.login-button{
  border-left: 1px solid #cacaca;
  border-right: 1px solid #cacaca;
  height: auto;
}
.logo-container{
  margin-top: 20%;
}
.toast_noty{
  background:white;
  width: 280px;
  margin: 0 auto;
  min-height: 154px;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.19);
  border-radius: 10px;
  max-height: 90vh;
  overflow: scroll;
  position: relative;
}
.header_noti,
.footer_noti{
  padding: 10px 20px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
}
.header_noti{
  text-transform: uppercase;
  text-align: left;
}
.footer_noti{
  text-align: right;
  color: #F2B415;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.body_noti{
  padding: 0 20px 47px 20px;
  .icon_noti{
    font-size: 35px;
    display: inline-block;
    vertical-align: middle;
  }
  .text_noti{
    display: inline-block;
    width: calc(100% - 35px);
    text-align: left;
    line-height: 1.2;
    padding-left: 10px;
    vertical-align: middle;
  }
}
</style>
